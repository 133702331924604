<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-row class="mb-0">
          <b-col cols="2" class="borderRight pr-0">
            <b-card-body>
              <b-card-title>风险列表</b-card-title>

              <flat-pickr v-model="filterValues.time" :config="{
                                time_24hr: true,
                                mode: 'range',
                                locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                            }" class="form-control"/>
              <v-select v-model="filterValues.system" :options="filterOptions.system" placeholder="风险系统"
                        class="w-100 mt-1"/>
              <v-select :inputmode="false" v-model="filterValues.eqname" :options="filterOptions.eqname"
                        placeholder="风险设备" class="w-100 mt-1"/>
              <!-- <v-select
v-model="filterValues.degree"
:options="['filterOptions.degree']"
placeholder="风险类型"
class="w-100 mt-50"
/>
-->
            </b-card-body>

            <hr class="my-0"/>

            <vue-perfect-scrollbar style="height: calc(100vh - 420px)">
              <app-collapse>
                <app-collapse-item title="程度" :isVisible="true">
                  <template v-slot:header>
                    <div class="w-100">
                      <span>等级</span>
                      <b-link class="float-right" @click.stop="
                                                handleShowPie([
                                                    {
                                                        label: '严重',
                                                        value: fault_level_3,
                                                    },
                                                    {
                                                        label: '提醒',
                                                        value: fault_level_2,
                                                    },
                                                    {
                                                        label: '一般',
                                                        value: fault_level_1,
                                                    },
                                                ])
                                                " v-b-modal:chart-modal>
                        <b-icon-pie-chart-fill/>
                      </b-link>
                    </div>
                  </template>
                  <b-list-group flush class="listGroupFilter">
                    <b-list-group-item style="cursor: pointer" @click="
                                            filterValues.degree = '严重'
                                            " :style="filterValues.degree === '严重'
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <span class="bullet bullet-danger bullet-sm mr-1"></span>
                      严重
                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.degree ===
                                                    '严重'
                                                " @click.stop="
                                                    filterValues.degree =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-danger">
                          {{
                            fault_level_3.toString()
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item style="cursor: pointer" @click="
                                            filterValues.degree = '提醒'
                                            " :style="filterValues.degree === '提醒'
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <span class="bullet bullet-warning bullet-sm mr-1"></span>
                      提醒
                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.degree ===
                                                    '提醒'
                                                " @click.stop="
                                                    filterValues.degree =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-warning" class="float-right">
                          {{
                            fault_level_2.toString()
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item style="cursor: pointer" @click="
                                            filterValues.degree = '一般'
                                            " :style="filterValues.degree === '一般'
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <span class="bullet bullet-info bullet-sm mr-1"></span>
                      一般

                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.degree ===
                                                    '一般'
                                                " @click.stop="
                                                    filterValues.degree =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-info" class="float-right">
                          {{
                            fault_level_1.toString()
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </app-collapse-item>

                <app-collapse-item title="状态" :isVisible="true">
                  <template v-slot:header>
                    <div class="w-100">
                      <span>状态</span>
                      <b-link class="float-right" @click.stop="
                                                handleShowPie([
                                                    {
                                                        label: '待处置',
                                                        value: count_state[0],
                                                    },
                                                    {
                                                        label: '已推送',
                                                        value: count_state[1],
                                                    },
                                                    {
                                                        label: '已忽略',
                                                        value: count_state[2],
                                                    },
                                                ])
                                                " v-b-modal:chart-modal>
                        <b-icon-pie-chart-fill/>
                      </b-link>
                    </div>
                  </template>
                  <b-list-group flush class="listGroupFilter">
                    <b-list-group-item class="cursor-pointer" @click="filterValues.state = 0"
                                       :style="filterValues.state === 0
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <b-icon-bell-fill class="mr-1"/>
                      待处理
                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.state === 0
                                                " @click.stop="
                                                    filterValues.state =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-primary" class="float-right">{{
                            count_state[0]
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="cursor-pointer" @click="filterValues.state = 1"
                                       :style="filterValues.state === 1
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <b-icon-check2-square class="mr-1"/>
                      已推送
                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.state === 1
                                                " @click.stop="
                                                    filterValues.state =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-primary" class="float-right">{{
                            count_state[1]
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="cursor-pointer" @click="filterValues.state = 2"
                                       :style="filterValues.state === 2
                                                ? `border-left: 3px solid #1890ff;`
                                                : ''
                                                ">
                      <b-icon-trash-fill class="mr-1"/>
                      已忽略
                      <div class="float-right d-flex align-items-center">
                        <feather-icon v-if="
                                                    filterValues.state === 2
                                                " @click.stop="
                                                    filterValues.state =
                                                    null
                                                    " icon="XIcon" class="text-secondary mr-75"/>
                        <b-badge pill variant="light-primary" class="float-right">{{
                            count_state[2]
                          }}
                        </b-badge>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </app-collapse-item>

                <app-collapse-item title="分类" :isVisible="true">
                  <template v-slot:header>
                    <div class="w-100">
                      <label class="text-muted my-0">
                        系统默认为您屏蔽了以下风险信息
                      </label>
                    </div>
                  </template>

                  <b-list-group flush class="listGroupFilter">
                    <b-list-group-item style="cursor: pointer" @click="
                                            handleExcludeCategory(
                                                '连续恒值'
                                            )
                                            ">
                      <b-icon-eye-fill v-if="
                                                !filterValues.category.includes(
                                                    '连续恒值'
                                                )
                                            " class="mr-1"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else class="mr-1"></b-icon-eye-slash-fill>
                      连续恒值
                      <b-badge pill variant="light-primary" class="float-right">{{ fault_cate_1 }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item style="cursor: pointer" @click="
                                            handleExcludeCategory(
                                                '无效点过多'
                                            )
                                            ">
                      <b-icon-eye-fill v-if="
                                                !filterValues.category.includes(
                                                    '无效点过多'
                                                )
                                            " class="mr-1"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else class="mr-1"></b-icon-eye-slash-fill>
                      无效点过多
                      <b-badge pill variant="light-primary" class="float-right">{{ fault_cate_2 }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item style="cursor: pointer" @click="
                                            handleExcludeCategory(
                                                '报警检查'
                                            )
                                            ">
                      <b-icon-eye-fill v-if="
                                                !filterValues.category.includes(
                                                    '报警检查'
                                                )
                                            " class="mr-1"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else class="mr-1"></b-icon-eye-slash-fill>
                      报警检查
                      <b-badge pill variant="light-primary" class="float-right">{{ fault_cate_3 }}
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </app-collapse-item>
              </app-collapse>
            </vue-perfect-scrollbar>

            <b-card-footer class="pt-1 d-flex align-items-center justify-content-between text-muted">
              <span>共扫描到</span>
              <b-badge pill variant="light-primary">{{
                  fault_level_1 +
                  fault_level_2 +
                  fault_level_3
                }}
              </b-badge>
              <span>风险漏洞</span>
            </b-card-footer>
          </b-col>

          <b-col cols="10" class="pl-0">
            <div class="d-flex align-items-center justify-content-between pl-2 pr-25 pt-50 pb-25">
              <div class="d-flex align-items-center w-100">
                <b-checkbox @change="handleChangeAllSelect" v-model="isAllSelected"
                            v-b-tooltip.hover.v-primary title="全选"></b-checkbox>
                <!-- Searchbar -->
                <b-input-group class="input-group-merge fixedSearch w-75">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted"/>
                  </b-input-group-prepend>
                  <b-form-input @keyup.enter="refetchData" v-model="fault_name"
                                placeholder="Search"/>
                </b-input-group>
              </div>

              <div class="d-flex align-items-center">
                <b-button v-if="selectIds.length" variant="flat-primary" class="btn-icon rounded-circle"
                          v-b-modal:repair-list-print-modal v-b-tooltip.hover.v-primary title="短信推送">
                  <b-icon-chat-fill/>
                </b-button>

                <b-button v-if="selectIds.length" variant="flat-primary"
                          class="btn-icon rounded-circle ml-50" v-b-tooltip.hover.v-primary
                          @click="handlePushWechat()" title="微信推送">
                  <i class="bi bi-wechat" style="font-size: 1.25rem"></i>
                </b-button>

                <b-button v-if="selectIds.length" variant="flat-primary"
                          class="btn-icon rounded-circle ml-50" v-b-tooltip.hover.v-primary
                          v-b-modal:repair-list-print-modal-to-app title="APP推送">
                  <b-icon-app-indicator/>
                </b-button>

                <b-button v-if="selectIds.length" variant="flat-primary"
                          class="btn-icon rounded-circle ml-50" @click="handleClickPush(2)"
                          v-b-tooltip.hover.v-primary title="全部忽略">
                  <b-icon-trash-fill/>
                </b-button>

                <b-dropdown right no-caret variant="link">
                  <template #button-content>
                    <b-icon-three-dots-vertical/>
                  </template>

                  <b-dropdown-item @click="
                                        filterValues.timeOrderBy = 'desc'
                                        " v-if="
                                            filterValues.timeOrderBy === 'asc'
                                        ">
                    <b-icon-sort-down class="mr-1"/>
                    逆序
                  </b-dropdown-item>
                  <b-dropdown-item @click="
                                        filterValues.timeOrderBy = 'asc'
                                        " v-if="
                                            filterValues.timeOrderBy === 'desc'
                                        ">
                    <b-icon-sort-up class="mr-1"/>
                    正序
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <b-icon-download class="mr-1"/>
                    下载
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <hr class="my-0"/>

            <vue-perfect-scrollbar style="height: calc(100vh - 257px)">
              <!-- Risk list -->
              <b-list-group flush class="listGroupContent">
                <b-list-group-item v-for="(items, index) of dataList"
                                   @click.stop="showDetails(items[0])" @mouseenter="items[0].mouse_in = true"
                                   @mouseleave="items[0].mouse_in = false" :key="index" :style="items[0].id === detailModal.data.id &&
                                        selectIds.length <= 1
                                        ? `box-sizing: border-box; border-left: 3px solid #1890ff;`
                                        : ''
                                        " class="cursor-pointer px-2 py-1">
                  <b-row>
                    <b-col cols="10" class="d-flex align-items-center">
                      <div @click.stop>
                        <b-checkbox class="mr-1" v-model="selectIds"
                                    :value="items[0].id"></b-checkbox>
                      </div>
                      <div style="min-width: 0">
                        <h5 class="d-flex align-items-center">
                                                    <span class="bullet bullet-sm mr-1" :class="'bullet-' +
                                                        getDegreeColor(
                                                            items[0].degree
                                                        )
                                                        "></span>

                          {{ items[0].function_name }}

                          <span class="ml-50" style="font-size: 1rem">
                                                        <b-badge v-if="
                                                            items.length > 1
                                                        " @click.stop="
                                                            items[0].fold =
                                                            !items[0]
                                                                .fold
                                                            " pill :variant="'light-' +
                                                                getDegreeColor(
                                                                    items[0]
                                                                        .degree
                                                                )
                                                                ">
                                                            {{
                                                            items[0].fold
                                                                ? "- collapse"
                                                                : "+" +
                                                                (items.length -
                                                                    1) +
                                                                " more"
                                                          }}
                                                        </b-badge></span>
                        </h5>
                        <p class="text-truncate mb-25">
                          <small>
                            <b-icon-clock-fill class="mr-25"/>
                            {{
                              items[0].create_date
                            }}

                            <b-icon-hdd-fill class="ml-1 mr-25">
                            </b-icon-hdd-fill>
                            {{ items[0].eqname }}

                            <span class="text-muted">
                                                            <b-icon-upc-scan class="ml-1 mr-25"></b-icon-upc-scan>
                                                            {{
                                items[0].equsid
                              }}
                                                        </span>
                          </small>
                        </p>

                        <div class="text-truncate text-muted">
                          探测到{{
                            items[0].eqname
                          }}存在{{
                            items[0].function_name
                          }}风险，该风险来自于{{
                            items[0].system
                          }}，属于{{
                            items[0].type
                          }}型故障，风险等级为{{
                            items[0].degree
                          }}，可能由{{
                            items[0].fault_reason
                          }}等原因造成，建议{{
                            items[0].fault_solution
                          }}。
                        </div>
                      </div>
                    </b-col>

                    <b-col cols="2" class="d-flex align-items-center justify-content-end">
                      <div v-if="!items[0].mouse_in" class="text-right">
                        <b-badge pill :variant="'light-' +
                                                    getDegreeColor(
                                                        items[0].degree
                                                    )
                                                    ">
                          {{ items[0].degree }}
                        </b-badge>

                        <b-badge pill class="ml-50" :variant="'light-' +
                                                    getTypeColor(
                                                        items[0].type
                                                    )
                                                    ">
                          {{ items[0].type }}
                        </b-badge>

                        <b-badge pill class="ml-50" :variant="'light-' +
                                                    getStateColor(
                                                        items[0].state
                                                    )
                                                    ">
                                                    <span v-if="
                                                        items[0].state === 0
                                                    ">
                                                        <b-icon-bell-fill class="mr-25"/>待处理
                                                    </span>
                          <span v-if="
                                                        items[0].state === 1
                                                    ">
                                                        <b-icon-check2-square class="mr-25"/>已推送
                                                    </span>
                          <span v-if="
                                                        items[0].state === 2
                                                    ">
                                                        <b-icon-trash-fill class="mr-25"/>已忽略
                                                    </span>
                        </b-badge>
                      </div>

                      <div v-else>
                        <b-button size="sm" variant="flat-primary"
                                  class="btn-icon rounded-circle" v-b-tooltip.hover.v-primary
                                  v-b-modal:repair-list-print-modal @click.stop="() => {
                                                        detailModal.data =
                                                            items[0];
                                                        select_alert = [];
                                                    }
                                                        " title="短信推送">
                          <b-icon-chat-fill/>
                        </b-button>

                        <b-button size="sm" variant="flat-primary"
                                  class="btn-icon rounded-circle ml-50" v-b-tooltip.hover.v-primary
                                  @click.stop="
                                                        handlePushWechat(
                                                            items[0]
                                                        )
                                                        " title="微信推送">
                          <i class="bi bi-wechat" style="
                                                            font-size: 1.125rem;
                                                        "></i>
                        </b-button>

                        <b-button size="sm" variant="flat-primary"
                                  class="btn-icon rounded-circle ml-50" v-b-tooltip.hover.v-primary
                                  v-b-modal:repair-list-print-modal-to-app title="APP推送" @click.stop="() => {
                                                        detailModal.data =
                                                            items[0];
                                                        select_alert = [];
                                                    }
                                                        ">
                          <b-icon-app-indicator/>
                        </b-button>

                        <b-button size="sm" :disabled="items[0].state === 2" @click.stop="
                                                    handleClickPush(
                                                        2,
                                                        items[0]
                                                    )
                                                    " variant="flat-primary" class="btn-icon rounded-circle ml-50"
                                  v-b-tooltip.hover.v-primary title="忽略">
                          <b-icon-trash-fill></b-icon-trash-fill>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- ============ 展开的内容 ============ -->
                  <hr v-if="items[0].fold" class="offsetMargin"/>

                  <b-list-group flush :style="`height: ${items[0].fold
                                        ? (items.length - 1) * 101.47
                                        : '0'
                                        }px;`" style="
                                            overflow: hidden;
                                            transition: 0.1s all ease;
                                        ">
                    <b-list-group-item @click.stop="showDetails(item)"
                                       @mouseenter="item.mouse_in = true" @mouseleave="item.mouse_in = false"
                                       :style="item.id ===
                                                detailModal.data.id &&
                                                selectIds.length <= 1
                                                ? `box-sizing: border-box; border-left: 3px solid #1890ff;`
                                                : ''
                                                " v-for="(item,index) of items.length > 1
                                                    ? items.slice(1, items.length)
                                                    : []" class="cursor-pointer px-2 py-1"
                                       :key="index"
                    >
                      <b-row>
                        <b-col cols="10" class="d-flex align-items-center">
                          <div @click.stop>
                            <b-checkbox class="mr-1" v-model="selectIds" @click.stop
                                        :value="item.id"></b-checkbox>
                          </div>
                          <div style="min-width: 0">
                            <h5 class="d-flex align-items-center">
                                                            <span class="bullet bullet-sm mr-1" :class="'bullet-' +
                                                                getDegreeColor(
                                                                    item.degree
                                                                )
                                                                "></span>

                              {{
                                item.function_name
                              }}
                            </h5>
                            <p class="text-truncate mb-25">
                              <small>
                                <b-icon-clock-fill class="mr-25"/>
                                {{
                                  item.create_date
                                }}

                                <b-icon-hdd-fill class="ml-1 mr-25">
                                </b-icon-hdd-fill>
                                {{
                                  item.eqname
                                }}

                                <span class="text-muted">
                                                                    <b-icon-upc-scan
                                                                        class="ml-1 mr-25"></b-icon-upc-scan>
                                                                    {{
                                    item.equsid
                                  }}
                                                                </span>
                              </small>
                            </p>

                            <div class="text-truncate text-muted">
                              探测到{{
                                item.eqname
                              }}存在{{
                                item.function_name
                              }}风险，该风险来自于{{
                                item.system
                              }}，属于{{
                                item.type
                              }}型故障，风险等级为{{
                                item.degree
                              }}，可能由{{
                                item.fault_reason
                              }}等原因造成，建议{{
                                item.fault_solution
                              }}。
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="2" class="d-flex align-items-center justify-content-end">
                          <div v-if="!item.mouse_in" class="text-right">
                            <div>
                              <b-badge pill :variant="'light-' +
                                                                getDegreeColor(
                                                                    item.degree
                                                                )
                                                                ">
                                {{
                                  item.degree
                                }}
                              </b-badge>

                              <b-badge pill class="ml-50" :variant="'light-' +
                                                                getTypeColor(
                                                                    item.type
                                                                )
                                                                ">
                                {{ item.type }}
                              </b-badge>

                              <b-badge pill class="ml-50" :variant="'light-' +
                                                                getStateColor(
                                                                    item.state
                                                                )
                                                                ">
                                                                <span v-if="
                                                                    item.state ===
                                                                    0
                                                                ">
                                                                    <b-icon-bell-fill class="mr-25"/>待处理
                                                                </span>
                                <span v-if="
                                                                    item.state ===
                                                                    1
                                                                ">
                                                                    <b-icon-check2-square class="mr-25"/>已推送
                                                                </span>
                                <span v-if="
                                                                    item.state ===
                                                                    2
                                                                ">
                                                                    <b-icon-trash-fill class="mr-25"/>已忽略
                                                                </span>
                              </b-badge>
                            </div>
                          </div>

                          <div v-else>
                            <b-button size="sm" variant="flat-primary"
                                      class="btn-icon rounded-circle" v-b-tooltip.hover.v-primary
                                      v-b-modal:repair-list-print-modal @click.stop="() => {
                                                                detailModal.data =
                                                                    item;
                                                                select_alert =
                                                                    [];
                                                            }
                                                                " title="短信推送">
                              <b-icon-chat-fill/>
                            </b-button>

                            <b-button size="sm" variant="flat-primary"
                                      class="btn-icon rounded-circle ml-50"
                                      v-b-tooltip.hover.v-primary @click.stop="
                                                                handlePushWechat(
                                                                    item
                                                                )
                                                                " title="微信推送">
                              <i class="bi bi-wechat" style="
                                                                    font-size: 1.125rem;
                                                                "></i>
                            </b-button>

                            <b-button size="sm" variant="flat-primary"
                                      class="btn-icon rounded-circle ml-50"
                                      v-b-tooltip.hover.v-primary
                                      v-b-modal:repair-list-print-modal-to-app title="APP推送"
                                      @click.stop="() => {
                                                                detailModal.data =
                                                                    item;
                                                                select_alert = [];
                                                            }
                                                                ">
                              <b-icon-app-indicator/>
                            </b-button>

                            <b-button size="sm" :disabled="item.state === 2" @click.stop="
                                                            handleClickPush(
                                                                2,
                                                                item
                                                            )
                                                            " variant="flat-primary"
                                      class="btn-icon rounded-circle ml-50"
                                      v-b-tooltip.hover.v-primary title="忽略">
                              <b-icon-trash-fill></b-icon-trash-fill>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                  <!-- ============ 展开的内容结束 ============ -->
                </b-list-group-item>
              </b-list-group>

              <!-- Loading -->
              <div v-if="loading" style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                " class="text-center">
                <div>努力加载中...</div>
                <b-spinner label="Loading..." style="width: 4rem; height: 4rem" class="my-2"
                           variant="primary"/>

                <div class="text-muted">Loading ...</div>
              </div>

              <!-- Nothing found -->
              <div v-if="!dataList.length && !loading" style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                " class="text-center">
                <div>未查询到数据</div>
                <b-icon-exclamation-triangle-fill scale="5" class="my-3"/>
                <div class="text-muted">No items found</div>
              </div>
            </vue-perfect-scrollbar>

            <b-pagination v-model="currentPage" :total-rows="total" align="center" class="mt-1 mb-2"
                          first-number last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card>

      <!-- Message order -->
      <b-modal id="repair-list-print-modal" centered size="sm" title="短信推送" :ok-disabled="!select_alert.length"
               cancel-title="取消" ok-title="确认" @ok="handleClickPush(1)">
        <b-card-text>确认将此风险消息通过短信推送至以下联系人？
        </b-card-text>
        <v-select v-model="select_alert" dir="ltr" multiple placeholder="请选择联系人" label="title" :options="alert_list.map((item) => ({
                    value: item.id,
                    title: item.name,
                }))
                    "/>
        <!--        <div v-for="item of alert_list" style="display: flex">-->
        <!--          <b-checkbox-->
        <!--              v-model="select_alert"-->
        <!--              :value="item.id"-->
        <!--          ></b-checkbox>-->
        <!--          <span>{{ item.name }}</span>-->
        <!--        </div>-->
      </b-modal>

      <!-- APP order -->
      <b-modal id="repair-list-print-modal-to-app" centered size="sm" title="APP推送"
               :ok-disabled="!select_alert.length" cancel-title="取消" ok-title="确认" @ok="handleClickPush(1)">
        <b-card-text>确认将此风险消息通过APP推送至以下联系人？
        </b-card-text>
        <v-select v-model="select_alert" dir="ltr" placeholder="暂无联系人" multiple label="title"/>
      </b-modal>

      <!-- Stats chart -->
      <b-modal id="chart-modal" centered :title="'分布'" hide-footer>
        <div id="degree_pie1" style="height: 300px"></div>
      </b-modal>

      <!-- Deduction details -->
      <b-modal @ok="handleToDetail" v-model="recordInfoShow" id="detail-modal" size="xl" title="推理过程" hide-footer
               centered>
        <AgentDetailRight :id="detailModal.data.id" :modal="true"></AgentDetailRight>
      </b-modal>

      <!-- Sidebar -->
      <b-sidebar @hidden="handleCloseSidebar" v-model="isRightInfoBoxShow" id="sidebar-task-handler"
                 sidebar-class="sidebar-lg" width="840px" bg-variant="white" backdrop shadow right no-header>
        <div v-if="detailModal.show && selectIds.length <= 1">
          <b-card-body class="content-sidebar-header">
            <b-card-title class="text-truncate">
              {{ detailModal.data.eqname }}
              {{ detailModal.data.function_name }}
            </b-card-title>
            <b-card-sub-title class="text-truncate">
              <small>
                <b-icon-clock-fill class="mr-25">
                </b-icon-clock-fill>
                {{ detailModal.data.create_date }}
              </small>
              <small class="ml-1">
                <b-icon-hdd-fill class="mr-25">
                </b-icon-hdd-fill>
                {{ detailModal.data.eqname }}
              </small>
              <small class="ml-1">
                <b-icon-bell-fill class="mr-25">
                </b-icon-bell-fill>
                {{ detailModal.data.degree }}
              </small>
              <small class="ml-1">
                <b-icon-tag-fill class="mr-25">
                </b-icon-tag-fill>
                {{ detailModal.data.type }}
              </small>
            </b-card-sub-title>
          </b-card-body>

          <div class="d-flex">
            <!-- Expert -->
            <div class="borderRight w-50">
              <vue-perfect-scrollbar style="height: calc(100vh - 153px)">
                <b-card-body>
                  <b-card bg-variant="light-secondary">
                    SciPlat风险探针于<span class="text-info">{{
                      detailModal.data.create_date
                    }}</span>探测到<span class="text-info">{{
                      detailModal.data.eqname
                    }}</span>（<span class="text-info">#{{
                      detailModal.data.equsid
                    }}</span>）存在<span class="text-info">{{
                      detailModal.data.function_name
                    }}</span>风险，该风险来自于<span class="text-info">{{
                      detailModal.data.system
                    }}</span>，属于<span class="text-info">{{
                      detailModal.data.type
                    }}</span>型故障，风险等级为<span :class="'text-' +
                                                getDegreeColor(
                                                    detailModal.data.degree
                                                )
                                                ">{{ detailModal.data.degree }}</span>。
                  </b-card>

                  <b-card bg-variant="light-info">
                    <p>该风险可能是由于以下原因造成：</p>
                    {{
                      detailModal.data.fault_reason
                    }}
                  </b-card>


                  <b-card bg-variant="light-info">
                    <p>为了避免此类风险，你可以尝试以下措施：</p>
                    {{
                      detailModal.data.fault_solution
                    }}
                  </b-card>

                  <p>诊断出该风险的原始数据如下：</p>

                  <div v-show="detailModal.data.data" id="chart_agentdetail" style="height: 15rem">
                  </div>

                  <footer class="blockquote-footer text-right mt-2">
                    <small class="text-muted">
                      以上诊断结论由SciPlat专家系统提供
                      <!-- 你还可以尝试通过
<b-link @click="handleSendChatMessage">AI大模型
</b-link>
获得更多建议 -->
                    </small>
                  </footer>
                </b-card-body>
              </vue-perfect-scrollbar>

              <hr class="my-0"/>

              <div class="d-flex align-items-center justify-content-between pt-50 pb-1 px-2">
                <div>
                  <b-button variant="flat-primary" class="btn-icon rounded-circle"
                            v-b-modal:repair-list-print-modal v-b-tooltip.hover.v-primary title="短信推送">
                    <b-icon-chat-fill/>
                  </b-button>

                  <b-button variant="flat-primary" class="btn-icon rounded-circle ml-50"
                            v-b-tooltip.hover.v-primary @click="handlePushWechat(detailModal.data)"
                            title="微信推送">
                    <i class="bi bi-wechat" style="font-size: 1.25rem"></i>
                  </b-button>

                  <b-button variant="flat-primary" class="btn-icon rounded-circle ml-50"
                            v-b-tooltip.hover.v-primary v-b-modal:repair-list-print-modal-to-app
                            title="APP推送">
                    <b-icon-app-indicator/>
                  </b-button>

                  <b-button :disabled="detailModal.data.state == 2" variant="flat-primary"
                            class="btn-icon rounded-circle ml-50" v-b-tooltip.hover.v-primary title="忽略"
                            @click.stop="
                                            handleClickPush(
                                                2,
                                                detailModal.data
                                            )
                                            ">
                    <b-icon-trash-fill/>
                  </b-button>
                </div>
                <div>
                  <b-button variant="flat-primary" class="btn-icon rounded-circle"
                            v-b-tooltip.hover.v-primary @click="recordInfoShow = true" title="推理过程">
                    <b-icon-info-circle-fill></b-icon-info-circle-fill>
                  </b-button>
                </div>
              </div>
            </div>

            <!-- Chat -->
            <div class="w-50">
              <div class="p-2">
                <b-radio-group :disabled="Boolean(detailModal.chatMessageLoading)" v-model="tabSelect">
                  <b-radio :value="0">ChatGPT</b-radio>
                  <b-radio :value="1">文心一言</b-radio>
                </b-radio-group>
              </div>
              <hr class="my-0"/>
              <!--              <b-tabs :disabled="detailModal.chatMessageLoading" v-model="tabSelect" fill class="mt-1">-->
              <!--                -->
              <!--                <b-tab title="OpenAI">-->

              <!--                </b-tab>-->
              <!--                <b-tab title="文心一言">-->
              <!--                </b-tab>-->
              <!--                -->
              <!--              </b-tabs>-->
              <vue-perfect-scrollbar style="height: calc(100vh - 295px)" id="vpscroll">
                <b-card-body>

                  <div class="d-flex">
                    <b-avatar rounded variant="light-secondary" class="mr-1">
                      <i class="bi bi-robot" style="font-size: 1.25rem"></i>
                    </b-avatar>
                    <b-card no-body bg-variant="light-secondary" class="px-1 py-75">
                      Hi，我是SciPlat智能诊断机器人，欢迎向我咨询问题👏🏻
                    </b-card>
                  </div>

                  <template v-for="item of detailModal.chats">
                    <div v-if="item.role === 'user'" class="d-flex justify-content-end">
                      <b-card no-body bg-variant="light-warning" class="px-1 py-75">
                        {{ item.content }}
                      </b-card>
                      <b-avatar rounded variant="light-warning" class="ml-1">
                      </b-avatar>
                    </div>
                    <div v-else class="d-flex">

                      <b-avatar v-if="item.model === 'chatGPT'"
                                src="https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/23141715078460_.pic.jpg"
                                rounded variant="light-secondary" class="mr-1">
                      </b-avatar>
                      <b-avatar v-if="item.model === 'baidu'"
                                src="https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/23061715077871_.pic.jpg"
                                rounded variant="light-secondary" class="mr-1">
                      </b-avatar>
                      <!--                      <b-avatar rounded variant="light-warning" class="mr-1">-->
                      <!--                        <i class="bi bi-robot" style="font-size: 1.25rem"></i>-->
                      <!--                      </b-avatar>-->
                      <!--                      <b-card no-body bg-variant="light-warning" class="px-1 py-75">-->
                      <!--                        {{ item.content }}-->
                      <!--                      </b-card>-->
                      <b-card no-body v-if="item.model === 'chatGPT'" bg-variant="light-success"
                              class="px-1 py-75">
                        {{ item.content }}
                      </b-card>
                      <b-card no-body v-if="item.model === 'baidu'" bg-variant="light-primary"
                              class="px-1 py-75">
                        {{ item.content }}
                      </b-card>
                    </div>
                  </template>

                  <!-- detailModal.chatMessageLoading -->
                  <div class="d-flex" v-if="detailModal.chatMessageLoading">

                    <b-avatar v-if="tabSelect === 0"
                              src="https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/23141715078460_.pic.jpg"
                              rounded variant="light-secondary" class="mr-1">
                    </b-avatar>
                    <b-avatar v-if="tabSelect === 1"
                              src="https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/23061715077871_.pic.jpg"
                              rounded variant="light-secondary" class="mr-1">
                    </b-avatar>

                    <!--                    <b-avatar rounded variant="light-secondary" class="mr-1">-->
                    <!--                      <i class="bi bi-robot" style="font-size: 1.25rem"></i>-->
                    <!--                    </b-avatar>-->
                    <b-card no-body bg-variant="light-secondary" class="px-1 py-75">
                      {{ detailModal.chatMessageLoading }}
                    </b-card>
                  </div>

                  <footer class="blockquote-footer text-right">
                    <small class="text-muted">
                      以上信息由AI大模型生成
                      <!-- 返回<b-link @click="rightInfoTabIndex = 1">专家诊断</b-link> -->
                    </small>
                  </footer>
                </b-card-body>
              </vue-perfect-scrollbar>

              <hr class="my-0"/>

              <div class="position-relative">
                <b-form-textarea v-model="detailModal.chatContent" id="textarea-default" no-resize
                                 @keydown.enter.prevent="handleSendChatMessage"
                                 style="border: 0; padding: 1rem 1.5rem !important;"
                                 placeholder="你还可以继续问我问题..."
                                 rows="5"/>
                <b-button class="btn-icon rounded-circle position-absolute"
                          style="bottom: 1rem; right: 2rem;" variant="primary"
                          :disabled="Boolean(detailModal.chatMessageLoading)" @click="handleSendChatMessage"
                          v-b-tooltip.hover.v-primary title="发送">
                  <i class="bi bi-send-fill"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Multiple items selected -->
        <div v-if="selectIds.length > 1" style="
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    " class="text-center">
          <div>{{ selectIds.length }} items selected</div>
          <b-icon-envelope-open-fill scale="5" class="my-3"></b-icon-envelope-open-fill>
          <div>
            <b-button v-b-modal:repair-list-print-modal pill variant="flat-primary">
              <b-icon-capslock-fill class="mr-50"></b-icon-capslock-fill>
              推送工单
            </b-button>
          </div>
          <div class="mt-50">
            <b-button @click="handleClickPush(2)" pill variant="flat-primary">
              <b-icon-trash-fill class="mr-50"></b-icon-trash-fill>
              全部忽略
            </b-button>
          </div>
          <hr/>
          <div>
            <b-button @click="handleCancelSelected" pill variant="flat-secondary">
              <b-icon-x-circle class="mr-50"></b-icon-x-circle>
              取消选择
            </b-button>
          </div>
        </div>

        <!-- Nothing is selected -->
        <!--        <div-->
        <!--            v-else-->
        <!--            style="-->
        <!--                        position: absolute;-->
        <!--                        left: 50%;-->
        <!--                        top: 50%;-->
        <!--                        transform: translate(-50%, -50%);-->
        <!--                    "-->
        <!--            class="text-center"-->
        <!--        >-->
        <!--          <div>-->
        <!--            <b-icon-arrow-left-circle-fill-->
        <!--                class="mr-25"-->
        <!--            ></b-icon-arrow-left-circle-fill>-->
        <!--            选择需要查看的风险事件-->
        <!--          </div>-->
        <!--          <b-icon-envelope-fill-->
        <!--              scale="5"-->
        <!--              class="my-3"-->
        <!--          ></b-icon-envelope-fill>-->
        <!--          <div class="text-muted">Nothing is selected</div>-->
        <!--        </div>-->
      </b-sidebar>
    </b-col>
  </b-row>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  toRefs,
  ref,
  watch,
  nextTick,
} from "@vue/composition-api";
import {showToast} from "@/libs/utils/showToast";
import {getFaultAgents, getFaultAgentsFold, getFaultAgentsLeftLevel} from "@/api/front";
import {
  getDiagnosisAlertList,
  getDiagnosisHandleEvent,
  getDiagnosisOptions,
  getDiagnosisSendWechatNotice,
} from "@/api/diagnosis";
import {momentJs} from "@/libs/utils/moment";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import Ripple from "vue-ripple-directive";
import PageTitle from "@/views/layout/PageTitle";
import {routerParams} from "@/libs/utils/routerParams";
import {Line, Area, Pie} from "@antv/g2plot";
import {t} from "@core/libs/i18n/utils";
import router from "@/router";
import FunctionEntry from "@/views/front/cabr/dashboard/components/FunctionEntry.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AgentDetailRight from "@/views/front/common/agent-list/components/AgentDetailRight.vue";
import {BSidebar} from "bootstrap-vue";
import {isNull} from "lodash";
import {showConfirm} from "@/libs/utils/showConfirm";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    FunctionEntry,
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
    AppCollapse,
    AppCollapseItem,
    VuePerfectScrollbar,
    AgentDetailRight,
    BSidebar,
  },
  props: ["hide-title"],
  setup() {
    const isRightInfoBoxShow = ref(false);
    const dataList = ref([]);
    const loading = ref(false);
    const fault_name = ref("");
    const rightInfoTabIndex = ref(1);
    const isAllSelected = ref(false);
    const selectIds = ref([]);
    const degree_pie = ref(null);
    const tabSelect = ref(0);
    const alert_list = ref([]);
    const select_alert = ref([]);
    getDiagnosisAlertList().then((res) => {
      alert_list.value = res.data;
    });
    const handleTaskClick = (taskData) => {
      task.value = taskData;
      isTaskHandlerSidebarActive.value = true;
    };
    const event = reactive({
      recordInfoShow: false,
      chartTab: 0,
      fault_level_3: 0,
      fault_level_2: 0,
      fault_level_1: 0,
      fault_cate_3: 0,
      fault_cate_2: 0,
      fault_cate_1: 0,
      count_state: [0, 0, 0],
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      filterOptions: {},
      filterValues: {
        degree: null,
        state: null,
        system: "",
        time: momentJs().format(),
        eqname: "",
        timeOrderBy: "desc",
        function_name: null,
        category: ["连续恒值", "无效点过多", "报警检查"],
      },
      isEditAgentSidebarActive: false,
      refAgentListTable: null,
      perPage: 20,
      searchQuery: "",
      tableColumns: [
        {
          label: "#",
          key: "index",
        },
        {
          label: "故障名称",
          key: "fault_name",
        },
        {
          label: "故障程度",
          key: "degree",
        },
        {
          label: "故障等级",
          key: "primary_class",
          sortable: true,
        },
        {
          label: "方程名称",
          key: "function_name",
        },
        {
          label: "所属系统",
          key: "system",
        },
        {
          label: "故障时间",
          key: "create_date",
        },
        {
          label: "操作",
          key: "actions",
        },
      ],
      total: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "create_date",
      isSortDirDesc: true,
      dataMeta: computed(() => {
        const localItemsCount = 10;
        return {
          from:
              event.perPage * (event.currentPage - 1) +
              (localItemsCount ? 1 : 0),
          to:
              event.perPage * (event.currentPage - 1) +
              localItemsCount,
          of: event.total,
        };
      }),
      detailModal: {
        show: false,
        data: {},
        chatContent: '',
        chats: [],
        chatMessageLoading: ''
      },
      isBusy: false,
    });
    watch(
        [
          () => event.currentPage,
          () => event.perPage,
          () => event.isEditAgentSidebarActive,
          () => event.searchQuery,
        ],
        () => {
          if (!event.isEditAgentSidebarActive) {
            refetchData();
          }
        }
    );

    const reset = () => {
      selectIds.value = [];
      isAllSelected.value = false;
      resetDetailModal()
    };

    watch(() => event.currentPage, reset);

    watch(event.filterValues, () => {
      refetchData();
    });

    const handleCancelSelected = () => {
      reset();
    };

    const showDetails = (detail) => {
      // if (selectIds.value.length > 1) return;
      event.detailModal.chatContent = `${detail.system}${detail.function_name}是什么原因？该怎么解决？`
      event.detailModal.show = false;
      event.detailModal.data = {}
      rightInfoTabIndex.value = 1
      event.detailModal.chats = []
      let data
      event.detailModal.chatMessageLoading = ''
      nextTick(() => {
        if (selectIds.value.length <= 1) {
          event.detailModal.show = true;
          isRightInfoBoxShow.value = true;
          event.detailModal.data = detail;
          data = JSON.parse(detail.data);
        }
        // let chartData = [];
        // data.days.map((timeItem, timeIndex) => {
        //   chartData.push({
        //     time: timeItem,
        //     value: data.data[0][timeIndex],
        //     agent: data.legend[0],
        //   });
        // });
        const getDoubleFullNumber = (n) => (n < 10 ? "0" + n : n);
        const chartData = [];
        for (let i in data.time) {
          const d = new Date(data.time[i]);
          chartData.push({
            time: `${d.getFullYear()}-${d.getMonth() + 1 < 10
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1
            }-${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()
            } ${getDoubleFullNumber(
                d.getHours()
            )}:${getDoubleFullNumber(
                d.getMinutes()
            )}:${getDoubleFullNumber(d.getSeconds())}`,
            pvvarname: data.pvvarname[i],
            value: data.value[i],
          });
        }
        console.log('chartDatachartDatachartData', chartData)
        event.detailModal.data.chartData = chartData;
        setTimeout(() => {
          handleSendChatMessage()
          const area = new Area("chart_agentdetail", {
            data: chartData,
            xField: "time",
            yField: "value",
            seriesField: 'pvvarname',
            xAxis: {
              grid: null,
            },
            yAxis: {
              grid: null,
            },
            slider: {
              start: 0,
              end: 1,
              textStyle: {
                fill: "#eeeeee",
                fillOpacity: 1,
                shadowColor: "black",
                shadowBlur: 1,
              },
              trendCfg: {
                lineStyle: {
                  fillOpacity: 1,
                },
              },
            },
            animate: true,
            animation: {
              appear: {
                animation: "wave-in",
                duration: 10000,
              },
            },
          });
          area.render();
        }, 1000);

      });
    };

    const resetDetailModal = () => {
      event.detailModal = {
        show: false,
        data: {},
        chatContent: '',
        chats: [
          // {
          //   "role": "system",
          //   "content": "Hi，我是SciPlat智能诊断机器人，欢迎向我咨询问题👏🏻"
          // },
        ],
        chatMessageLoading: ''
      }
    }

    const refetchData = () => {
      fetchAgents();
    };

    const fetchAgents = () => {
      event.isBusy = true;
      dataList.value = [];
      const filters = getFilters();
      const params = {
        project_id: event.project_id,
        pagesize: event.perPage,
        page: event.currentPage,
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        fault_name: fault_name.value,
        ...filters,
      };
      loading.value = true;
      getFaultAgentsLeftLevel(params).then(res => {
        const {
          level_1,
          level_2,
          level_3,
        } = res.data;
        event.fault_level_1 = level_1;
        event.fault_level_2 = level_2;
        event.fault_level_3 = level_3;
      })
      getList(params)
          .then((response) => {
            const {
              list,
              total,
              // level_1,
              // level_2,
              // level_3,
              cate_1,
              cate_2,
              cate_3,
              count_state,
            } = response.data;
            // callback(list);
            dataList.value = list.map((items) => {
              items[0].fold = false;
              items.forEach((item) => {
                item.mouse_in = false;
              });
              return items;
            });
            event.total = total;
            // event.fault!!vel_3;
            event.fault_cate_1 = cate_1;
            event.fault_cate_2 = cate_2;
            event.fault_cate_3 = cate_3;
            event.count_state = count_state;
            if (!total) {
              showToast(null, "提示", "未查询到数据");
            }
            event.isBusy = false;
          })
          .catch((error) => {
            event.isBusy = false;
            showToast(error);
          })
          .finally(() => {
            loading.value = false;
          });
    };
    /**
     * 获取列表数据
     */
    const getList = async (params) => {
      return getFaultAgentsFold({
        project_id: event.project_id,
        ...params,
      });
    };
    /**
     * 获取参数
     */
    const getOptions = () => {
      return getDiagnosisOptions({
        project_id: event.project_id,
      })
          .then((response) => {
            event.filterOptions = response.data;
          })
          .catch((error) => {
            showToast(error);
          });
    };
    /**
     * 处理导出数据的头部
     * @param fields
     */
    const exportExcelHeaders = (fields) => {
      let headers = {};
      fields.map((item) => {
        if (item.key !== "index" && item.key !== "actions") {
          let header = t(item.label) + "(" + item.key + ")";
          headers[header] = item.key;
        }
      });
      return headers;
    };
    /**
     * 获取筛选条件
     */
    const getFilters = () => {
      let filters = {};
      for (const Key in event.filterValues) {
        let Value = event.filterValues[Key];
        if (Key === "state" && !isNull(Value)) {
          filters.state = Value;
          continue;
        }
        if (!!Value) {
          filters[Key] = Value;
          if (Key === "time") {
            Value = Value.replaceAll(" 至 ", ",").replaceAll(
                " to ",
                ","
            );
            const timeArr = Value.split(",");
            const start = momentJs(timeArr[0])
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
            const end = momentJs(timeArr[1] || timeArr[0])
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
            filters[Key] = "(" + start + "," + end + ")";
          }
        }
      }
      return filters;
    };
    /**
     * 下载数据
     * @returns {Promise<*>}
     */
    const downloadData = async () => {
      const filters = getFilters();
      const params = {
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
      const res = await getList(params);
      if (!res.data.total) {
        showToast(null, "提示", "暂无数据可以下载");
      }
      return res.data.list;
    };

    const ToAgentDetail = (detail) => {
      router.push({
        name: "AgentDetail",
        params: {id: detail.id, detail},
      });
    };

    const handleChangeAllSelect = (v) => {
      const data = [];
      dataList.value.forEach((items) =>
          items.forEach((item) => data.push(item))
      );
      if (v) {
        selectIds.value = data
            .map((item) => item.id);
      } else {
        selectIds.value = [];
      }
    };

    const handleChangeDegree = (degree) => {
      event.filterValues.degree = degree;
    };

    const handlePush = (item, step) => {
      nextTick(() => {
        item.state = step;
      });
    };

    const handleBatchPush = (v, selectids) => {
      getDiagnosisHandleEvent({
        ids: [...selectids],
        state: v,
        alert_list: select_alert.value.map((item) => item.value),
      }).then(() => {
        const flatDataList = [];
        dataList.value.forEach((items) =>
            items.forEach((item) => flatDataList.push(item))
        );
        for (let item of flatDataList) {
          if (selectids.includes(item.id)) {
            item.state = v;
          }
        }
        selectIds.value = [];
        isAllSelected.value = false;
        // fetchAgents();
      });
    };

    const handleToDetail = () => {
      router.push({
        name: "AgentDetail2",
        params: {
          id: event.detailModal.data.id,
        },
      });
    };

    const getDegreeColor = (degree) => {
      switch (degree) {
        case "一般":
          return "info";
        case "提醒":
          return "warning";
        case "严重":
          return "danger";
      }
    };

    const getTypeColor = (type) => {
      switch (type) {
        case "安全":
          return "primary";
        case "舒适":
          return "info";
        case "节能":
          return "success";
        default:
          return "secondary";
      }
    };

    const getStateColor = (state) => {
      switch (state) {
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "secondary";
      }
    };

    const handleClickPush = (state = 1, data = event.detailModal.data) => {
      event.detailModal.data = data
      if (state === 2) {
        showConfirm("确认忽略选择消息？", () => {
          const ids = [...selectIds.value];
          if (data) {
            ids.push(data.id);
          }
          handleBatchPush(2, ids);
        });
        return;
      }
      const ids = [...selectIds.value];
      if (data) {
        ids.push(data.id);
      }

      handleBatchPush(state, ids);
    };

    const handleExcludeCategory = (category) => {
      // handleExcludeCategory
      const index = event.filterValues.category.findIndex(
          (item) => item == category
      );
      if (index > -1) {
        event.filterValues.category.splice(index, 1);
        return;
      } else {
        event.filterValues.category.push(category);
      }
    };

    const handleShowPie = (data) => {
      setTimeout(() => {
        if (degree_pie.value) {
          degree_pie.value.destroy();
        }
        degree_pie.value = new Pie("degree_pie1", {
          // appendPadding: 10,
          data,
          forceFit: true,
          // width: 500,
          // height: 500,
          angleField: "value",
          colorField: "label",
          radius: 1,
          innerRadius: 0.6,
          statistic: {
            title: {
              formatter: () => "合计",
              offsetY: -7,
              style: {
                color: "",
              },
            },
            content: {
              offsetY: 7,
              style: {
                color: "",
              },
            },
          },
          label: {
            content: "{value}",
            style: {
              color: "",
            },
          },
          legend: {
            text: {
              style: {
                color: "",
              },
            },
          },
          pieStyle: {
            lineWidth: 0,
          },
        });
        degree_pie.value.render();
      }, 400);
    };

    const handlePushWechat = (item = null) => {
      if (!item && !selectIds.value.length) {
        return showToast(null, "提示", "未选中任何信息");
      }
      let ids = []
      select_alert.value = []
      if (!item) {
        ids = [...selectIds.value]
      } else {
        ids = [item.id]
        event.detailModal.data = item
      }
      showConfirm(
          "确认将此风险消息通过微信推送至所有联系人？",
          () => {
            getDiagnosisSendWechatNotice({
              ids,
              state: 1,
            }).then(() => {
              if (item.state == 0) item.state = 1;
            });
            // updateLayout();
          },
          null,
          true,
          "https://simulation-1255710621.cos.ap-nanjing.myqcloud.com/assets/images/wechat.svg",
          null
      );
    };

    let vpscroll = null

    const CancelToken = axios.CancelToken;
    let axiosCancelSource = null
    const handleSendChatMessage = () => {
      if (event.detailModal.chatMessageLoading) return;
      if (!vpscroll) {
        vpscroll = document.getElementById('vpscroll')
      }
      event.detailModal.chats.push({
        "role": "user",
        "content": event.detailModal.chatContent
      })
      event.detailModal.chatMessageLoading = '思考中...'
      event.detailModal.chatContent = ''
      nextTick(() => {
        vpscroll.scrollTop = vpscroll.scrollHeight
      })
      let message = []
      console.log(tabSelect.value, tabSelect.value === 0)
      if (tabSelect.value === 0) {
        message = [
          {
            "role": "system",
            "content": "Hi，我是SciPlat智能诊断机器人，欢迎向我咨询问题👏🏻"
          },
          ...event.detailModal.chats
        ]
      } else {
        message = [...event.detailModal.chats]
      }
      axiosCancelSource = CancelToken.source();
      axios({
        method: 'post',
        url: tabSelect.value === 0 ? 'https://api.nextapi.fun/v1/chat/completions' : '/api/diagnosis/fault/chat_ai',
        data: {
          "messages": message,
          "stream": true,
          "model": "gpt-3.5-turbo",
          "temperature": 0.5,
          "presence_penalty": 0,
          "frequency_penalty": 0,
          "top_p": 1,
        },
        headers: {
          'Authorization': 'Bearer ak-1sD0rwGYiq4Mz6qXn2ENpT5Jmz9AjgYchjAmBR913k68TTYp'
        },
        responseType: 'stream',
        onDownloadProgress: (progressEvent) => {
          let resultStr = ''
          if (tabSelect.value === 0) {
            progressEvent.currentTarget.responseText.split('\n\n').forEach(item => {
              if (!item) return;
              const everyJsonStr = `{"data": ${item.slice(6)}}`
              try {
                const everyMessageJson = JSON.parse(everyJsonStr)
                everyMessageJson.data.choices.forEach(i => {
                  if (i.delta.content)
                    resultStr += i.delta.content;
                })
                event.detailModal.chatMessageLoading = resultStr
                vpscroll.scrollTop = vpscroll.scrollHeight
              } catch (e) {
                event.detailModal.chats.push({
                  "role": "assistant",
                  "model": 'chatGPT',
                  "content": event.detailModal.chatMessageLoading
                })
                event.detailModal.chatMessageLoading = ''
                vpscroll.scrollTop = vpscroll.scrollHeight
              }
            })
          }

        },
        cancelToken: axiosCancelSource.token
      }).then(res => {
        console.log(res, 'resres')
        if (tabSelect.value === 1) {
          console.log(res, 'resres')
          event.detailModal.chats.push({
            "role": "assistant",
            "model": 'baidu',
            "content": JSON.parse(res.data).result
          })
          event.detailModal.chatMessageLoading = ''
          nextTick(() => {
            vpscroll.scrollTop = vpscroll.scrollHeight
          })
        }
      })

    }

    const handleCloseSidebar = () => {
      if (axiosCancelSource) {
        axiosCancelSource.cancel('Operation canceled by the user.');
        axiosCancelSource = null
      }
      resetDetailModal()
    }

    return {
      ...toRefs(event),
      isRightInfoBoxShow,
      fetchAgents,
      handleToDetail,
      exportExcelHeaders,
      handleChangeAllSelect,
      handleCancelSelected,
      downloadData,
      handleBatchPush,
      getOptions,
      handleChangeDegree,
      handleExcludeCategory,
      isAllSelected,
      fault_name,
      handlePush,
      handleShowPie,
      zh,
      selectIds,
      showDetails,
      ToAgentDetail,
      handlePushWechat,
      dataList,
      loading,
      degree_pie,
      alert_list,
      select_alert,
      rightInfoTabIndex,
      getDegreeColor,
      getTypeColor,
      getStateColor,
      handleClickPush,
      handleTaskClick,
      refetchData,
      handleSendChatMessage,
      handleCloseSidebar,
      tabSelect
    };
  },
  mounted() {
    if (this.$route.params?.eqname) {
      this.filterValues.eqname = this.$route.params.eqname;
    }
    switch (this.$route.query?.key) {
      case '一般':
      case '提醒':
      case '严重':
        this.filterValues.degree = this.$route.query?.key
        return;
      case '已处理':
        this.filterValues.state = 1
        return;
      case '已忽略':
        this.filterValues.state = 2
        return;
    }

    this.getOptions().then(() => {
      this.fetchAgents();
    });
  },
};
</script>

<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
@import "../../../../@core/scss/vue/libs/vue-select";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.0/font/bootstrap-icons.css");

/* Lucas */
.noWrap2 {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.noScrollbar::-webkit-scrollbar {
  width: 0 !important;
}

.offsetMargin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.listGroupFilter {
  margin: -1rem;

  .list-group-item {
    border: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:hover {
      transform: translateY(-2px);
      transition: all 0.2s;
    }
  }
}

.item-border-top {
  border-top: 1px solid $border-color;
}

.radio-border-bottom {
  border-bottom: 1px solid $border-color;
}

.listGroupContent .list-group-item {
  &:hover {
    border-top: 1px solid $border-color;
    transform: translateY(-4px);
    transition: all 0.4s;
  }
}

.fixedSearch {
  &:focus-within {
    box-shadow: none !important;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}

::v-deep .btnDropdown {
  .btn {
    padding: 0.5rem;
    border-radius: 50%;
  }
}


.tabsPreview .nav-tabs {
  margin-bottom: 0 !important;
}

#detail-modal .modal-body {
  padding: 0 !important;
}
</style>
