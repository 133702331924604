<template>
    <div class="d-flex align-items-center">
        <b-avatar class="mr-1" rounded size="56" variant="light-primary">
            <feather-icon
                v-if="router_params && router_params === 'parking'"
                icon="GridIcon"
                size="27"
            />
            <!-- <i
          v-if="icon.indexOf('icon-') !== -1"
          :class="icon && `iconfont ${icon}`"
          style="font-weight: 300; font-size: 2rem"
      ></i> -->
            <i
                v-else-if="icon && icon.indexOf('icon-') !== -1"
                :class="icon && `iconfont ${icon}`"
                style="font-weight: 300; font-size: 2rem"
            ></i>
            <!-- <feather-icon v-else :icon="icon || 'GridIcon'" size="27" /> -->
            <b-icon-columns-gap v-else scale="2"/>
        </b-avatar>
        <h3
            v-if="router_params && router_params === 'parking'"
            class="my-0 font-title"
        >
            智能停车
        </h3>
        <h3 v-else class="my-0 font-title">
            {{ $t(title) }}
        </h3>
    </div>
</template>

<script>
import store from "@/store";
import { computed, reactive, toRefs } from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "PageTitle",
    setup() {
        const event = reactive({
            show: false,
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            icon: computed(() => {
                return store.state.menus.current.params.icon;
            }),
            title: computed(() => {
                return store.state.menus.current.params.title;
            }),
            router_params: computed(() => {
                return routerParams("system_key");
            }),
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style>
/* 李立引入 */
.font-title {
    font-family: HYLingXin, serif;
}

.font-digit {
    font-family: LcdD, serif;
}
</style>
